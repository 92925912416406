/* eslint-disable import/no-unresolved */

import { Capacitor } from '@capacitor/core';
import Dashboard from '@/pages/Dashboard/Dashboard.vue';
import Modules from '@/pages/Modules/Modules.vue';
import Workshop from '@/pages/Modules/Workshop.vue';
import Reports from '@/pages/Reports/Reports.vue';
import Medias from '@/pages/Medias/Medias.vue';
import MediasList from '@/pages/Medias/MediasList.vue';
import Goals from '@/pages/Goals/Goals.vue';
import AddGoal from '@/pages/Goals/AddGoal.vue';
import GoalForm from '@/pages/Goals/GoalForm.vue';
import Settings from '@/pages/Settings.vue';
import Profile from '@/pages/Profile.vue';
import Help from '@/pages/Help.vue';
import DashboardLayout from '@/components/Dashboard/Layout/DashboardLayout.vue';

import Login from '@/pages/PublicViews/Login.vue';
import StudyLogin from '@/pages/PublicViews/StudyLogin.vue';
import NotFound from '@/pages/PublicViews/NotFoundPage.vue';
import AppAccessRestrictedPage from '@/pages/PublicViews/AppAccessRestrictedPage.vue';
import Register from '@/pages/PublicViews/Register.vue';
import ResetPassword from '@/pages/PublicViews/ResetPassword.vue';
import ForgotPassword from '@/pages/PublicViews/ForgotPassword.vue';
import QuizPage from '@/pages/Modules/QuizPage.vue';
import ProgramWelcome from '@/pages/Modules/ProgramWelcome.vue';

/* eslint-enable import/no-unresolved */
import config from '@/config';
import rolesMixin from '../mixins/rolesMixin';
import store from '../stores';

const authGuard = (to, from, next) => {
  const { user } = store.state;

  if (user && user[config.primaryKey]) {
    if (Capacitor.getPlatform() !== 'ios') {
      return next();
    }

    if (!user.studyCode) {
      return next('/restricted');
    }

    return next();
  }
  return next('/login');
};

// eslint-disable-next-line
const roleGuard = (role) => (to, from, next) => {
  if (!store.state.user || !store.state.user[config.primaryKey]) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('Roleguard::', 'User not connected, forcing logout');
    }
    store.commit('logout');
    return next('/login');
  }

  if (!store.state.user.roles || !rolesMixin.methods.userHasRole(role, store)) {
    next('/');
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log('Rolegard:: missing role ', role, 'redirecting');
    }
    return;
  }
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    console.log('Roleguard::', 'correct role');
  }
  return next();
};

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: (to) => {
      store.commit('user', '');
      store.dispatch('logout');
      if (store.state.selectedProgram) {
        return `/${store.state.selectedProgram.code}/login`;
      }
      return '/login';
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:programCode/login',
    name: 'ProgramLogin',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/:programCode/etude/:studyCode',
    name: 'StudyLogin',
    component: StudyLogin,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/:programCode/register',
    name: 'ProgramRegister',
    component: Register,
    meta: {
      title: 'Inscription',
    },
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: AppAccessRestrictedPage,
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    props: true,
  },
  {
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: ResetPassword,
    props: true,
  },
  {
    path: '/dashboard',
    redirect: '/app/dashboard',
  },
  {
    path: '/quiz/:quizId',
    component: QuizPage,
  },
  {
    path: '/welcome',
    component: ProgramWelcome,
    beforeEnter: authGuard,
  },
  {
    path: '/app/workshop/:id',
    name: 'Workshop',
    component: Workshop,
    beforeEnter: authGuard,
  },
  {
    path: '/app',
    name: 'App',
    component: DashboardLayout,
    beforeEnter: authGuard,
    redirect: '/app/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        props: true,
        beforeEnter: authGuard,
        meta: {
          title: 'Accueil',
        },
      },
      {
        path: 'modules',
        name: 'Modules',
        component: Modules,
        props: true,
        beforeEnter: authGuard,
      },
      {
        path: 'modules/:id',
        name: 'Modules',
        component: Modules,
        props: true,
        beforeEnter: authGuard,
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Reports,
        props: true,
        beforeEnter: authGuard,
        meta: {
          title: 'Indicateurs',
        },
      },
      {
        path: 'medias',
        name: 'Medias',
        component: Medias,
        props: true,
        beforeEnter: authGuard,
      },
      {
        path: 'medias/list',
        name: 'MediasList',
        component: MediasList,
        props: true,
        beforeEnter: authGuard,
      },
      {
        path: 'goals',
        name: 'Goals',
        component: Goals,
        props: true,
        meta: {
          title: 'Objectifs',
        },
      },
      {
        path: 'goals/add',
        name: 'AddGoal',
        component: AddGoal,
        props: true,
      },
      {
        path: 'goals/add/form',
        name: 'GoalForm',
        component: GoalForm,
        props: true,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
        props: true,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        props: true,
      },
      {
        path: 'help',
        name: 'help',
        component: Help,
        props: true,
      },
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default routes;
export { authGuard, roleGuard, routes };
