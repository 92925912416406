<template>
  <CWrapper>
    <Header>
      <template #title>
        <h3 class="text-primary pl-3 module-title">Module: {{ module && module.title }}</h3>
      </template>
    </Header>
    <div class="c-body workshop-page pt-0" :class="showTableOfContents ? '' : 'collapsed'">
      <div class="h-100" v-if="workshop && workshop.id">
        <!-- Chapters Panel -->
        <nav class="chapters-panel bg-beige-light">
          <div class="chapters-panel-inner" :style="showTableOfContents ? '' : 'overflow:hidden'">
            <div class="nav-header d-flex align-items-center">
              <div
                class="toggle-panel text-primary d-flex justify-content-center align-items-center"
                @click="
                  showTableOfContents ? (showTableOfContents = false) : (showTableOfContents = true)
                "
              >
                <CIcon name="arrowUp" width="24" height="24" />
              </div>
              Atelier {{ workshop.title }}
            </div>
            <ul v-if="workshop" class="nav nav-chapters flex-column flex-nowrap bg-beige-light">
              <li
                class="nav-item p-2"
                v-for="(section, sectionIndex) in workshop.Sections"
                :key="section.id"
              >
                <div class="d-flex align-items-center">
                  <CIcon :name="getSectionIcon(section)" width="22" height="22" />
                  <a
                    class="nav-link toc-chapter-title"
                    @click.prevent="selectSection(section, sectionIndex)"
                  >
                    <b>{{ section.title }}{{ section.subtitle ? ' : ' : '' }}</b
                    >{{ section.subtitle }}
                  </a>
                  <b class="ml-auto">{{ section.duration }}</b>
                </div>
                <ul class="nav nav-subchapters flex-column mt-2">
                  <template
                    v-if="
                      section.Chapters &&
                        (section.Chapters.length > 1 || (section.Quiz && section.Quiz.length))
                    "
                  >
                    <li
                      class="nav-item pl-4 pl-lg-5 py-1 py-2"
                      v-for="item in getSectionItems(section)"
                      :key="item.id"
                    >
                      <div class="d-flex align-items-center">
                        <CIcon :name="getSectionItemIcon(section, item)" width="22" height="22" />
                        <a
                          class="nav-link"
                          href="#"
                          @click.prevent="selectSectionItem(section, item)"
                          >{{ item.title }}</a
                        >
                        <b class="ml-auto">{{
                          item.estimatedDuration
                            ? convertDurationToHoursAndMinutes(item.estimatedDuration, {
                                short: true,
                              })
                            : ''
                        }}</b>
                      </div>
                    </li>
                  </template>
                </ul>
              </li>
            </ul>
          </div>
          <div
            class="workshop-progress
            d-flex justify-content-around align-items-center
            border-top border-white
            bg-beige-light"
          >
            <b class="mx-2">Progression de l'atelier</b>
            <CProgress :value="progress" color="dark" size="xs" class="w-50" />
            <b class="mx-2">{{ roundFloatValue(progress || 0) }}%</b>
          </div>
        </nav>
        <!-- End Chapters Panel -->

        <!-- Content -->
        <div class="content bg-white" v-if="selectedItem">
          <div class="content-main p-4 text-dark">
            <transition name="fade" mode="out-in">
              <h4 :key="selectedItem.id">
                {{ selectedItem.title }}
              </h4>
            </transition>
            <transition
              name="fade"
              mode="out-in"
              v-if="currentSection && selectedItem && isQuizItem(currentSection, selectedItem)"
            >
              <div :key="selectedItem.id" class="mb-3">
                {{ selectedItem.description }}
              </div>
            </transition>
            <transition name="img-fade" mode="out-in">
              <img
                :key="selectedItem.id"
                v-if="selectedItem.image"
                :src="selectedItem.image"
                class="float-left mb-4 mr-4"
                alt=""
              />
            </transition>
            <transition
              name="fade"
              mode="out-in"
              v-if="currentSection && selectedItem && isChapterItem(currentSection, selectedItem)"
            >
              <div :key="selectedItem.id" class="text-dark" v-html="selectedItem.content"></div>
            </transition>
            <transition
              name="fade"
              mode="out-in"
              v-if="currentSection && selectedItem && isQuizItem(currentSection, selectedItem)"
            >
              <QuizPage
                :key="selectedItem.id"
                :is-embedded="true"
                :quizId="selectedItem.id"
                @quiz-completed="onQuizCompleted"
              ></QuizPage>
            </transition>
          </div>
          <div
            class="content-footer bg-beige-light d-flex justify-content-between align-items-center px-3"
          >
            <button
              v-if="!hidePreviousButton"
              @click="goToPreviousChapter()"
              class="btn btn-outline-primary mx-2 ml-md-auto px-md-4 font-weight-bold order-md-2"
            >
              Précédent
            </button>
            <a
              href="#"
              @click.prevent="$router.push('/app/modules/' + workshop.moduleId)"
              class="text-center"
              >Retour à l'accueil du module</a
            >
            <button
              v-if="!showWorkshopCompletedButton"
              class="btn btn-primary mx-2 px-md-4 font-weight-bold order-md-3"
              @click="goToNextChapter()"
            >
              Suivant
            </button>
            <button
              v-if="showWorkshopCompletedButton"
              class="btn btn-primary mx-2 px-md-4 font-weight-bold order-md-3"
              @click="finishWorkshop()"
            >
              Terminer
            </button>
          </div>
        </div>
        <!-- End Content-->
      </div>
    </div>
    <CModal
      :show.sync="quizResultModal"
      :centered="true"
      size="sm"
      :backdrop="true"
      :closeOnBackdrop="false"
      @update:show="status => (quizScore = !status ? 0 : quizScore)"
    >
      <template #header>
        <h5 class="modal-title mb-3">Quiz terminé !</h5>
        <CButton @click="quizResultModal = false" class="close text-primary mt-2 mr-2">
          x
        </CButton>
      </template>
      <template #body-wrapper>
        <div class="text-center">
          Bravo d’avoir terminé ce quiz 👏🏻 ! Voici vos résultats ⬇️<br />
          <span v-html="quizGrading"></span><br />
        </div>
      </template>
      <template #footer-wrapper><div></div></template>
    </CModal>
  </CWrapper>
</template>

<script>
import Header from '@/components/Dashboard/Layout/Header.vue';
import QuizPage from '@/pages/Modules/QuizPage.vue';
import utils from '../../utils';

export default {
  name: 'Workshop',
  components: {
    Header,
    QuizPage,
  },
  async mounted() {
    await this.getModules();
    await this.getWorkshop();

    this.currentSectionItemIndex = 0;
    this.isMobile = document.body.offsetWidth && document.body.offsetWidth < 768;
    this.showTableOfContents = !this.isMobile;
    this.loadCurrentWorkshopContent();
    this.showContentSection = true;

    setTimeout(() => {
      if (!this.workshop) {
        return;
      }

      this.module = (this.$store.state.modules || []).find(
        item => item && item.id === this.workshop.moduleId,
      );
    });
  },
  computed: {
    currentSection() {
      if (this.currentSectionIndex === undefined || !this.workshop) {
        return false;
      }
      return this.workshop.Sections[this.currentSectionIndex];
    },
    selectedItem() {
      if (this.currentSectionItemIndex === undefined || !this.currentSection) {
        return false;
      }

      const sectionItems = this.getSectionItems(this.currentSection);

      return sectionItems && sectionItems[this.currentSectionItemIndex];
    },

    /*
    if there is no section after AND
    there is no chapters at all OR there is no chapter after AND
    there is no quiz at all OR there is no quiz after
    */
    showWorkshopCompletedButton() {
      const sectionItems = this.getSectionItems(this.currentSection);

      return (
        this.workshop
        && !this.workshop.Sections[this.currentSectionIndex + 1]
        && (!this.currentSection || !sectionItems || !sectionItems[this.currentSectionItemIndex + 1])
      );
    },

    hidePreviousButton() {
      return this.currentSectionIndex === 0 && this.currentSectionItemIndex === 0;
    },
  },
  data() {
    return {
      progress: 0,
      workshop: null,
      module: null,
      showTableOfContents: true,
      showContentSection: false,

      currentSectionItemIndex: 0,
      currentSectionIndex: 0,
      isMobile: false,

      quizScore: 0,
      quizGrading: '',
      quizResultModal: false,
    };
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    roundFloatValue: utils.roundFloatValue,
    getModules() {
      return this.$store.dispatch('getModules');
    },
    async getWorkshop() {
      const { data } = await this.$http.get(
        `/api/workshop/${this.$route.params.id}/toc?fullContent=1`,
      );
      if (data && data.body) {
        this.workshop = data.body;
        if (this.workshop.Sections && this.workshop.Sections[0]) {
          this.selectSection(this.workshop.Sections[0], 0);
        }
      }

      await this.getWorkshopProgress();
    },

    loadCurrentWorkshopContent() {
      if (!this.workshop) {
        return;
      }

      this.workshop.Sections = this.workshop.Sections || [];

      this.workshop.Sections.some((section, index) => {
        if (!section) {
          return false;
        }

        section.Chapters = section.Chapters || [];

        const sectionItems = this.getSectionItems(section) || [];

        const isChapterSelected = section.Chapters.some((chapter, i) => {
          if (!chapter) {
            return false;
          }

          if (
            !chapter.UsersProgressions
            || !chapter.UsersProgressions.length
            || !chapter.UsersProgressions[0]
            || !chapter.UsersProgressions[0].status
            || chapter.UsersProgressions[0].status === 'onGoing'
          ) {
            this.currentSectionIndex = index;
            this.currentSectionItemIndex = sectionItems.indexOf(chapter);
            return true;
          }

          return false;
        });

        if (isChapterSelected) {
          return true;
        }

        section.Quiz = section.Quiz || [];

        return section.Quiz.some((quiz, i) => {
          if (!quiz) {
            return false;
          }

          if (!quiz.UserResults || !quiz.UserResults.length || !quiz.UserResults[0]) {
            this.currentSectionIndex = index;
            this.currentSectionItemIndex = sectionItems.indexOf(quiz);
            return true;
          }

          return false;
        });
      });
    },

    async getWorkshopProgress() {
      const { data } = await this.$http.get(`/api/workshop?filters[id]=${this.$route.params.id}`);
      if (data && data.body && data.body[0]) {
        this.progress = data.body[0].progress || 0;
      }
      this.resetContentAreaScroll();
    },
    selectSection(section, index) {
      this.currentSectionIndex = index;
      this.currentSectionItemIndex = 0;
      this.hideTocIfNeeded();
    },

    selectSectionItem(section, chapter) {
      if (!this.workshop || !this.workshop.Sections) {
        return;
      }

      this.currentSectionIndex = this.workshop.Sections.indexOf(section);
      const sectionItems = this.getSectionItems(section) || [];
      this.currentSectionItemIndex = sectionItems.indexOf(chapter);

      this.hideTocIfNeeded();
    },

    hideTocIfNeeded() {
      if (this.isMobile) {
        this.showTableOfContents = false;
      }
    },

    async goToNextChapter() {
      this.showContentSection = false;

      if (this.selectedItem && this.isChapterItem(this.currentSection, this.selectedItem)) {
        await this.$http.put(`/api/chapter/${this.selectedItem.id}/complete`);
        this.selectedItem.UsersProgressions = [
          {
            status: 'done',
          },
        ];
      }
      setTimeout(() => {
        this.showContentSection = true;
      }, 100);

      this.currentSectionItemIndex++;

      if (this.selectedItem && this.isChapterItem(this.currentSection, this.selectedItem)) {
        await this.$http.put(`/api/chapter/${this.selectedItem.id}/complete`, {
          status: 'onGoing',
        });
        this.selectedItem.UsersProgressions = [
          {
            status: 'onGoing',
          },
        ];
        await this.getWorkshopProgress();
        return;
      }

      if (this.selectedItem && this.isQuizItem(this.currentSection, this.selectedItem)) {
        await this.getWorkshopProgress();
        return;
      }

      this.currentSectionIndex++;
      this.currentSectionItemIndex = 0;

      // set progression ongoing for the first chapter of the next section
      if (this.selectedItem && this.isChapterItem(this.currentSection, this.selectedItem)) {
        await this.$http.put(`/api/chapter/${this.selectedItem.id}/complete`, {
          status: 'onGoing',
        });
        this.selectedItem.UsersProgressions = [
          {
            status: 'onGoing',
          },
        ];
        this.$forceUpdate();
      }
      await this.getWorkshopProgress();
    },

    goToPreviousChapter() {
      this.showContentSection = false;
      this.currentSectionItemIndex--;

      setTimeout(() => {
        this.showContentSection = true;
      }, 100);

      if (this.selectedItem) {
        this.getWorkshopProgress();
        return;
      }

      this.currentSectionIndex--;
      const sectionItems = this.getSectionItems(this.currentSection) || [];

      this.currentSectionItemIndex
        = sectionItems && sectionItems.length ? sectionItems.length - 1 : 0;

      this.getWorkshopProgress();
    },

    resetContentAreaScroll() {
      window.scrollTo(0, 0);
    },

    getSectionItemIcon(section, sectionItem) {
      if (!section || !sectionItem) {
        return;
      }

      if (this.isQuizItem(section, sectionItem)) {
        return this.getQuizIcon(sectionItem);
      }
      if (!sectionItem.UsersProgressions || !sectionItem.UsersProgressions.length) {
        return 'chapterToDo';
      }
      if (sectionItem.UsersProgressions[0].status === 'done') {
        return 'chapterDone';
      }

      return 'chapterInProgress';
    },

    getQuizIcon(quiz) {
      if (quiz && quiz.UserResults && quiz.UserResults.length) {
        return 'chapterDone';
      }
      return 'chapterToDo';
    },

    getSectionIcon(section) {
      const progressionArray = section.Chapters.map(item =>
        this.getSectionItemIcon(section, item),)
        .concat(section.Quiz.map(item => this.getSectionItemIcon(section, item)));
      let onProgress = false;
      let done = false;

      if (progressionArray.filter(progression => progression === 'chapterInProgress').length > 0) {
        onProgress = true;
      }
      if (
        progressionArray.filter(progression => progression === 'chapterDone').length
        === progressionArray.length
      ) {
        done = true;
      }

      if (done) {
        return 'chapterDone';
      }
      if (onProgress) {
        return 'chapterInProgress';
      }
      return 'chapterToDo';
    },

    onQuizCompleted({ result }) {
      if (!result || !result[0]) {
        return;
      }

      if (
        this.currentSection
        && this.selectedItem
        && this.isQuizItem(this.currentSection, this.selectedItem)
      ) {
        this.selectedItem.UserResults = [result[0]];
      }

      this.quizScore = result[0].score;
      const rating = result && result[0] && result[0].rating;
      if (rating) {
        this.quizGrading = rating;
      }
      this.quizResultModal = true;
      if (!this.showWorkshopCompletedButton) {
        this.goToNextChapter();
      }
    },

    async finishWorkshop() {
      // complete last chapter
      const currentSectionItem = this.selectedItem;
      if (currentSectionItem && this.isChapterItem(this.currentSection, currentSectionItem)) {
        await this.$http.put(`/api/chapter/${currentSectionItem.id}/complete`, { status: 'done' });
      }

      if (this.workshop && this.workshop.moduleId) {
        this.$router.push(`/app/modules?moduleId=${this.workshop.moduleId}`);
      }

      this.getWorkshopProgress();
    },

    getSectionItems(section) {
      if (!section) {
        return [];
      }

      const quizzes = section.Quiz || [];
      const chapters = section.Chapters || [];

      return [...quizzes, ...chapters].sort((a, b) => {
        const orderA = a.order;
        const orderB = b.order;

        if (orderA < orderB) {
          return -1;
        }

        if (orderA > orderB) {
          return 1;
        }

        return 0;
      });
    },

    isChapterItem(section, item) {
      return section && section.Chapters && item && section.Chapters.includes(item);
    },

    isQuizItem(section, item) {
      return section && section.Quiz && item && section.Quiz.includes(item);
    },
  },
};
</script>
