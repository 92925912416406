<template>
  <CHeader fixed with-subheader primary class="bg-beige-light app-header dashboard-tour-show-step-2">
    <CToggler
      in-header
      v-if="currentPage !== 'Workshop'"
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <b v-if="currentPage !== 'Workshop'">{{ $t('common.nav.' + currentPage.toLowerCase()) }}</b>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <slot name="title">
        <!--
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      -->
        <div
          v-if="!isInitialAssessmentCompleted"
          class="notification"
          @click="returnToInitialAssessment"
        >
          <CIcon :src="warningIcon" />
          <span>{{ $t('common.labels.continueAssessment') }}</span>
        </div>
      </slot>
    </CHeaderNav>
    <CHeaderNav class="mr-lg-4">
      <!--
      <CHeaderNavItem class="d-md-down-none">
        <CHeaderNavLink>
          <CIcon :src="searchIcon" />
        </CHeaderNavLink>
      </CHeaderNavItem
      -->
      <HeaderNotificationsDropdown />
      <HeaderAccountDropdown />
    </CHeaderNav>
    <CSubheader class="px-3" v-if="isModules || isMedias || isSettings">
      <!-- <CBreadcrumbRouter class="border-0 mb-0" v-if="!isModules" /> -->
      <nav class="nav bg-white w-100 text-center justify-content-center subnavbar">
        <template v-if="isModules">
          <a
            v-for="(module, index) in $store.state.modules"
            class="nav-link"
            href="#"
            :class="
              $store.state.selectedModule && $store.state.selectedModule.id === module.id
                ? 'active'
                : ''
            "
            @click.prevent="selectModule(module.id)"
            :key="index"
          >
            <div>{{ upperFirst(module.title) }}</div>
          </a>
        </template>
        <a
          v-if="isMedias"
          v-for="(type, index) in ['video', 'podcast']"
          class="nav-link"
          href="#"
          :class="
            $store.state.selectedMediaTab && $store.state.selectedMediaTab === type ? 'active' : ''
          "
          @click.prevent="selectMediaTab(type)"
          :key="index"
        >
          <div>{{ upperFirst($tc('common.labels.' + type, 2)) }}</div>
        </a>
        <a
          v-if="isSettings"
          v-for="(tab, index) in ['perso', 'common']"
          class="nav-link f-12"
          href="#"
          :class="tab === $store.state.selectedSettingsTab ? 'active' : ''"
          @click.prevent="selectSettingsTab(tab)"
          :key="index"
          ><div>{{ upperFirst($t('common.nav.settingsTab.' + tab)) }}</div>
        </a>
      </nav>
    </CSubheader>
  </CHeader>
</template>

<script>
import { upperFirst, isObject } from 'lodash';
import searchIcon from '@/assets/icons/Search.svg';
import warningIcon from '@/assets/icons/Warning.svg';
import HeaderNotificationsDropdown from './HeaderNotificationsDropdown.vue';
import HeaderAccountDropdown from './HeaderAccountDropdown.vue';

export default {
  name: 'Header',
  components: {
    HeaderAccountDropdown,
    HeaderNotificationsDropdown,
  },
  computed: {
    currentPage() {
      return this.$route.name;
    },
    isModules() {
      return this.$route.name === 'Modules';
    },
    isMedias() {
      return this.$route.name === 'Medias';
    },
    isSettings() {
      return this.$route.name === 'Settings';
    },
    isInitialAssessmentCompleted() {
      const { user } = this.$store.state;
      return user && user.initialAssessmentCompleted;
    },
  },
  data: () => ({
    searchIcon,
    warningIcon,
  }),
  methods: {
    upperFirst,
    isObject,
    async selectModule(idOrObject) {
      if (!idOrObject) {
        return;
      }
      const module = isObject(idOrObject)
        ? idOrObject
        : this.$store.state.modules.find(m => m.id === idOrObject);
      this.$store.commit('selectedModule', module);
      this.$forceUpdate();
    },
    async selectMediaTab(type) {
      if (!type) {
        return;
      }
      this.$store.commit('selectedMediaTab', type);
      this.$forceUpdate();
    },
    selectSettingsTab(tab) {
      if (!tab) {
        return;
      }
      this.$store.commit('selectedSettingsTab', tab);
      this.$forceUpdate();
    },
    returnToInitialAssessment() {
      this.$store.dispatch('getInitialAssessmentData').then(() => {
        const { initialAssessmentQuizzes } = this.$store.state;

        if (initialAssessmentQuizzes && initialAssessmentQuizzes.length) {
          this.$router.push(`/quiz/${initialAssessmentQuizzes[0].id}`);
        }
      });
    },
  },
};
</script>
