<template>
  <div class="module-page tab-content" v-if="$store.state.modules">
    <div v-if="isTutorialModalDisplayed" class="module-page-overlay" />
    <CModal
      v-if="isTutorialModalDisplayed"
      :title="$t('common.modals.infoTitle')"
      :show.sync="showTutorialModal"
      :centered="true"
      size="md"
      @update:show="completeTutorial"
    >
      <div>{{ $t('common.labels.module-tutorial-1') }}</div>
      <div>{{ $t('common.labels.module-tutorial-2') }}</div>
      <div>{{ $t('common.labels.module-tutorial-3') }}</div>
      <template #header>
        <h5 class="modal-title">{{ $t('common.modals.infoTitle') }}</h5>
        <CButton @click="completeTutorial" class="close">
          <CIcon :src="closeIcon" />
        </CButton>
      </template>
    </CModal>
    <div
      class="tab-pane fade"
      :class="selectedModule && module && selectedModule.id === module.id ? 'show active' : ''"
      v-for="(module, index) in $store.state.modules"
      :key="index"
      id=""
      role="tabpanel"
    >
      <CRow>
        <CCol sm="12" md="7">
          <div class="card card-primary card-module h-100" text="first">
            <div class="card-body bg-white d-flex">
              <CRow class="w-100 align-content-start">
                <CCol col="12" class="module-title-container order-1">
                  <h3 :style="{ color: module.color || '#6B6FC6' }" class="text-nowrap">
                    {{ moduleTitle }}
                  </h3>
                </CCol>
                <CCol
                  xs="12"
                  md="4"
                  class="module-picture-container order-1 order-md-3 p-md-0 text-center"
                >
                  <div class="module-picture">
                    <img class="img-fluid" :src="module.image || getModuleImage(index)" />
                  </div>
                </CCol>
                <CCol xs="12" md="8" class="module-info order-2">
                  <div class="module-description">
                    <strong>Objectif du module :</strong>
                    {{ module.description }}
                  </div>
                </CCol>
                <CCol sm="12" md="4" class="order-3 btn-container pr-0">
                  <CButton color="success" class="" @click="continueModule">
                    {{ $t('common.buttons.begin') }}</CButton
                  >
                </CCol>
              </CRow>
            </div>
          </div>
        </CCol>
        <CCol sm="12" md="5" class="col-module-overview">
          <div class="card card-primary card-module-overview h-100" text="first">
            <div class="card-header bg-white">
              <b class="text-primary">Aperçu du module</b>
            </div>
            <div class="card-body bg-white">
              <ul>
                <li>
                  <CIcon :src="statusIcon" /><span
                    ><b>Statut : </b>{{ ' ' }} {{ getModuleStatusLabel() }}</span
                  >
                </li>
                <li>
                  <CIcon :src="clockIcon" /><span
                    ><b>Durée estimée : </b>{{ ' ' }}
                    {{ convertDurationToHoursAndMinutes(module.estimatedDuration) }}</span
                  >
                </li>
                <li>
                  <CIcon :src="checkListIcon" /><span
                    ><b>Ateliers : </b>{{ ' ' }} {{ workshopsCount }}</span
                  >
                </li>
                <li>
                  <CIcon :src="videoIcon" /><span><b>Médias : </b>{{ ' ' }}{{ mediasCount }}</span>
                </li>
                <li>
                  <CIcon :src="goalIcon" /><span
                    ><b>Ma progression : </b> {{ roundFloatValue(getModuleProgress(), 0) }}%</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </CCol>
      </CRow>
      <h3 class="text-primary mt-4 mb-3 workshop-block-title">Ateliers</h3>
      <CRow class="pb-4 flex-nowrap flex-md-wrap overflow-auto">
        <CCol
          col="9"
          sm="5"
          md="4"
          xl="3"
          v-for="(item, key) in workshops"
          :key="key"
          class="col-workshop"
        >
          <div class="card card-workshop">
            <div class="card-img-container">
              <img v-if="item.image" class="card-img-top" :src="item.image" alt="Workshop image" />
            </div>
            <div class="card-body">
              <h5 class="card-title mb-4">{{ item.title }}</h5>
              <p class="card-text">
                {{ getWorkshopText(item) }}
              </p>
            </div>
            <div class="card-footer" :class="{ started: getWorkshopStatus(item) !== 'notStarted' }">
              <a
                href="#"
                class="btn btn-block text-left p-2"
                @click="$router.push('/app/workshop/' + item.id)"
                ><CIcon :src="getWorkshopStatusIcon(item)" /><b class="workshop-status">{{
                  $t(`common.labels.${getWorkshopStatus(item)}`)
                }}</b>
                <h3 class="workshop-action" @click="() => handleNextWorkshopAction(item)">
                  {{ getNextWorkshopActionLabel(item) }}
                </h3>
                <b class="workshop-progress">{{ roundFloatValue(item.progress || 0, 0) }}%</b>
              </a>
            </div>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import utils from '../../utils';

import nutritionImg from '../../assets/images/nutrition.svg';
import formeImg from '../../assets/images/forme.svg';
import equilibreImg from '../../assets/images/equilibre.svg';
import phytotherapieImg from '../../assets/images/phytotherapie.svg';
import statusIcon from '../../assets/icons/Status.svg';
import clockIcon from '../../assets/icons/Clock.svg';
import checkListIcon from '../../assets/icons/CheckList.svg';
import videoIcon from '../../assets/icons/Video.svg';
import goalIcon from '../../assets/icons/Goal.svg';
import closeIcon from '../../assets/icons/Close.svg';

import statusNotStartedIcon from '../../assets/icons/StatusNotStarted.svg';
import statusInProgressIcon from '../../assets/icons/StatusInProgress.svg';
import statusFinishedIcon from '../../assets/icons/StatusCompleted.svg';

export default {
  name: 'Modules',
  data() {
    return {
      workshops: [],
      medias: [],
      moduleImages: [nutritionImg, formeImg, equilibreImg, phytotherapieImg],
      statusIcon,
      clockIcon,
      checkListIcon,
      videoIcon,
      goalIcon,
      closeIcon,
      statusNotStartedIcon,
      statusInProgressIcon,
      statusFinishedIcon,
      showTutorialModal: true,
      moduleStats: [],
    };
  },
  async mounted() {
    await this.getModules();
    if (this.$route.params && this.$route.params.id) {
      const currentModule = this.modules.find(
        m => m && m.id === parseInt(this.$route.params.id, 10),
      );
      this.selectModule(currentModule);
    } else {
      this.selectModule(this.modules && this.modules[0]);
    }
    await this.getWorkshops();
    await this.getMedias();
    await this.getModuleStats();
  },
  computed: {
    modules() {
      return this.$store.state.modules;
    },
    moduleTitle() {
      return (
        this.selectedModule && this.selectedModule.title && this.selectedModule.title.toUpperCase()
      );
    },
    workshopsCount() {
      return this.workshops ? this.workshops.length : 0;
    },
    mediasCount() {
      return this.medias ? this.medias.length : 0;
    },
    selectedModule() {
      return this.$store.state.selectedModule;
    },
    isTutorialModalDisplayed() {
      return this.$store.state.user && !this.$store.state.user.tutorialCompleted;
    },
  },
  watch: {
    // eslint-disable-next-line
    '$store.state.selectedModule': function() {
      this.getWorkshops();
      this.getMedias();
      this.getModuleStats();
    },
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    roundFloatValue: utils.roundFloatValue,
    async completeTutorial() {
      const { user } = this.$store.state;
      if (!user) {
        return;
      }

      await this.$http.put(`/api/user/${user.id}`, {
        tutorialCompleted: true,
      });

      this.$store.dispatch('refreshUser');
      this.showTutorialModal = false;
    },
    getModuleImage(index) {
      if (!this.modules || !this.modules[index]) {
        return;
      }

      const module = this.modules[index];

      return module.image || this.moduleImages[index % this.modules.length];
    },
    async getModuleStats() {
      const selectedProgramId
        = this.$store.state.selectedProgram && this.$store.state.selectedProgram.id;
      if (!selectedProgramId) {
        return;
      }

      const { data } = await this.$http.get(
        `/api/stats/dashboard/module?filters[programId]=${selectedProgramId}`,
      );
      if (data && data.body) {
        this.moduleStats = data.body || [];
      }
    },
    getModuleProgress() {
      const moduleId = this.$store.state.selectedModule && this.$store.state.selectedModule.id;

      if (!moduleId || !this.moduleStats || !this.moduleStats.length) {
        return 0;
      }

      const item = this.moduleStats.find(obj => obj && obj.id === moduleId);

      if (!item) {
        return 0;
      }

      return item.progress;
    },
    getModuleStatus() {
      const progress = this.getModuleProgress();

      return this.getStatusByProgressValue(progress);
    },
    getWorkshopStatus(workshop) {
      return this.getStatusByProgressValue(workshop && workshop.progress);
    },
    getNextWorkshopAction(workshop) {
      const status = this.getWorkshopStatus(workshop);

      switch (status) {
        case 'finished':
          return 'restart';
        case 'inProgress':
          return 'continue';
        default:
          return 'start';
      }
    },
    getNextWorkshopActionLabel(workshop) {
      return this.$t(`common.labels.${this.getNextWorkshopAction(workshop)}`);
    },
    async handleNextWorkshopAction(workshop) {
      if (!workshop) {
        return;
      }

      const status = this.getWorkshopStatus(workshop);

      if (status === 'finished') {
        await this.$http.post(`/api/workshop/${workshop.id}/reset`);
      }

      this.$router.push(`/app/workshop/${workshop.id}`);
    },
    getWorkshopStatusIcon(workshop) {
      const status = this.getWorkshopStatus(workshop);

      switch (status) {
        case 'finished':
          return this.statusFinishedIcon;
        case 'inProgress':
          return this.statusInProgressIcon;
        default:
          return this.statusNotStartedIcon;
      }
    },
    getStatusByProgressValue(progress) {
      if (progress === 100) {
        return 'finished';
      }

      if (progress > 0 && progress < 100) {
        return 'inProgress';
      }

      return 'notStarted';
    },
    getModuleStatusLabel() {
      return this.$t(`common.labels.${this.getModuleStatus()}`);
    },
    async selectModule(idOrObject) {
      if (!idOrObject) {
        return;
      }
      const module = _.isObject(idOrObject)
        ? idOrObject
        : this.$store.state.modules.find(m => m && m.id === idOrObject);
      this.$store.commit('selectedModule', module);
    },

    getModules() {
      return this.$store.dispatch('getModules');
    },

    async getWorkshops() {
      if (!this.$store.state.selectedModule) {
        return;
      }
      const { data } = await this.$http.get(
        `/api/workshop?filters[moduleId]=${this.$store.state.selectedModule.id}&filters[isPublished]=1&sort[order]=ASC`,
      );
      if (data && data.body) {
        this.workshops = data.body.filter(workshop => {
          if (!workshop || !workshop.isPublished) {
            return false;
          }

          workshop.Sections = (workshop.Sections || []).filter(
            section =>
              section
              && section.Chapters
              && (section.Chapters || []).filter(
                chapter => chapter && chapter.content && chapter.isPublished,
              ).length,
          );

          return !!workshop.Sections.length;
        });
      }
    },

    async getMedias() {
      if (!this.$store.state.selectedModule) {
        return;
      }
      const { data } = await this.$http.get(
        `/api/media?filters[moduleId]=${this.$store.state.selectedModule.id}`,
      );
      if (data && data.body) {
        this.medias = data.body;
      }
    },

    continueModule() {
      if (!this.workshops || !this.workshops.length) {
        return;
      }

      const nextWorkshop = this.workshops.find(workshop => workshop && workshop.progress < 100);
      if (nextWorkshop) {
        this.$router.push(`/app/workshop/${nextWorkshop.id}`);
      }
    },

    getWorkshopText(item) {
      let text = '';
      if (!item) {
        return text;
      }

      if (item.content) {
        text = item.content.length > 80 ? `${item.content.slice(0, 80)}...` : item.content;
        return text;
      }

      if (item.description) {
        text
          = item.description.length > 80 ? `${item.description.slice(0, 80)}...` : item.description;
        return text;
      }

      return text;
    },
  },
};
</script>

<style scoped>
.card-footer .btn-block {
  border-radius: 0px;
}
</style>
