<template>
  <div class="c-app page-login flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4" class="auth-sidebar">
          <h3 class="d-sm-down-none">DOCLIVI</h3>
          <div class="progress-container">
            <div
              v-for="(step, index) in steps"
              :key="index"
              class="progress-step d-sm-down-none"
              :class="{ completed: isStepCompleted(step.name), current: step.name === currentStep }"
            >
              <div class="progress-step-label">{{ index + 1 }}</div>
              <div class="progress-step-name">{{ step.label }}</div>
            </div>
            <div class="d-md-none quiz-progress-mobile text-center">
              <div class="btn btn-link float-left d-sm-down-none" @click="() => goToRoute(`/${programCode}/login`)">
                {{ $t('common.buttons.leave') }}
              </div>
              <b class="d-sm-down-none">{{
                currentStep === 'generalInfo'
                  ? steps.find((step) => step.name === currentStep).label.split(' ')[0]
                  : steps.find((step) => step.name === currentStep).label
              }}</b>
              <div class="progress">
                <div class="progress-bar" role="progressbar" :style="{ width: progressBar + '%' }">
                  {{ $t('common.labels.step') }}
                  {{ steps.findIndex((step) => step.name === currentStep) + 1 }}
                </div>
                <div class="progress-bar filler" role="progressbar" :style="{ width: 100 - progressBar + '%' }"></div>
              </div>
            </div>
          </div>
        </CCol>
        <CCol md="8" class="content">
          <CRow>
            <CCol md="12" class="btn-container d-sm-down-none d-flex justify-content-end align-items-center">
              <span v-if="currentStep === 'generalInfo'" class="btn-description">{{ $t('common.labels.alreadyHaveAccount') }}?</span>
              <CButton v-if="currentStep === 'generalInfo'" variant="ghost" color="success" @click="() => goToRoute(`/${programCode}/login`)">{{
                $t('common.buttons.login')
              }}</CButton></CCol
            >
            <CCol md="12" class="form-container d-flex justify-content-center align-items-center">
              <div class="form-login d-flex flex-column justify-content-center align-items-start">
                <h3 v-if="currentStep === 'generalInfo'">
                  {{ $t('common.labels.generalInfoStepNameRight') }}
                </h3>
                <h3 v-if="currentStep === 'personalData'">
                  {{ $t('common.labels.personalDataStepName') }}
                </h3>
                <h3 v-if="currentStep === 'confirmation'">
                  {{ $t('common.labels.startInitialAssessment') }}
                </h3>
                <h4 v-if="currentStep === 'confirmation'">
                  {{ $t('common.labels.assessmentDescription') }}
                </h4>
                <h4 v-if="currentStep === 'confirmation'">
                  {{ $t('common.labels.assessmentDescription2') }}
                </h4>
                <CCard>
                  <CForm v-if="currentStep === 'generalInfo'" novalidate @submit.prevent="registerUser">
                    <CCardBody>
                      <CInput
                        type="email"
                        required
                        v-model="newUser.email"
                        autocomplete="email"
                        :addInputClasses="{
                          'is-valid': isEmailValid(),
                          'is-invalid': isFormSubmitted && !isEmailValid(),
                        }"
                        :label="$t('common.field.email')"
                        :placeholder="$t('common.placeholders.email')"
                        :invalid-feedback="$t(newUser.email ? 'common.labels.enterValidEmail' : 'common.labels.emptyField')"
                      />
                      <CInput
                        type="password"
                        required
                        :addInputClasses="{
                          'is-valid': isPasswordValid(newUser.password),
                          'is-invalid': isFormSubmitted && !isPasswordValid(newUser.password),
                        }"
                        v-model="newUser.password"
                        autocomplete="current-password"
                        :label="$t('common.labels.newPassword')"
                        :placeholder="$t('common.placeholders.password')"
                        :invalid-feedback="$t(newUser.password ? 'common.labels.passwordPolicy' : 'common.labels.emptyField')"
                      />

                      <CInput
                        type="password"
                        required
                        :addInputClasses="{
                          'is-valid': newUser.password && newUser.password === newUser.confirmPassword,
                          'is-invalid': isFormSubmitted && newUser.password && newUser.password !== newUser.confirmPassword,
                        }"
                        v-model="newUser.confirmPassword"
                        autocomplete="current-password"
                        :label="$t('common.labels.confirmNewPassword')"
                        :placeholder="$t('common.placeholders.password')"
                        :invalid-feedback="$t('common.labels.passwordsDoNotMatch')"
                      />
                    </CCardBody>
                    <CCardFooter>
                      <span class="text-danger" v-if="!programCode"
                        >Attention le programme <b>{{ programCode }}</b> n'existe pas.</span
                      >
                      <CButton type="submit" color="success" :disabled="!programCode || isRegisterButtonDisabled">
                        {{ $t('common.buttons.registerUser') }}</CButton
                      >
                    </CCardFooter>
                  </CForm>

                  <CForm v-if="currentStep === 'personalData'" novalidate @submit.prevent="completeRegistration">
                    <CCardBody>
                      <CInputRadioGroup
                        class="mb-4"
                        v-model="newUser.gender"
                        :options="genderOptions"
                        :checked.sync="newUser.gender"
                        :custom="true"
                        :inline="true"
                      />
                      <CInput
                        type="text"
                        required
                        v-model="newUser.firstName"
                        :addInputClasses="{
                          'is-valid': newUser.firstName,
                          'is-invalid': isFormSubmitted && !newUser.firstName,
                        }"
                        :label="$t('common.field.firstName')"
                        :placeholder="$t('common.placeholders.firstName')"
                        :invalid-feedback="$t('common.labels.emptyField')"
                      />
                      <CInput
                        type="text"
                        required
                        :addInputClasses="{
                          'is-valid': newUser.lastName,
                          'is-invalid': isFormSubmitted && !newUser.lastName,
                        }"
                        v-model="newUser.lastName"
                        :label="$t('common.field.lastName')"
                        :placeholder="$t('common.placeholders.lastName')"
                        :invalid-feedback="$t('common.labels.emptyField')"
                      />
                      <div data-v-2eab6031="" role="group" class="form-group">
                        <label class="">{{ $t('common.labels.dateOfBirth') }}</label>
                        <input :label="$t('common.labels.dateOfBirth')" type="date" id="birthdate-datepicker" class="form-control datepicker-input" />
                      </div>
                      <CInput
                        type="number"
                        v-model="newUser.weight"
                        :addInputClasses="{
                          'is-valid': newUser.weight,
                          'is-invalid': isFormSubmitted && !newUser.weight,
                        }"
                        :label="$t('common.field.weight')"
                        :placeholder="$t('common.placeholders.weight')"
                        :invalid-feedback="$t('common.labels.emptyField')"
                        append="Kg"
                      />
                      <CInput
                        type="text"
                        v-model="newUser.height"
                        :addInputClasses="{
                          'is-valid': newUser.height,
                          'is-invalid': isFormSubmitted && !newUser.height,
                        }"
                        :label="$t('common.field.height')"
                        :placeholder="$t('common.placeholders.height')"
                        :invalid-feedback="$t('common.labels.emptyField')"
                        append="cm"
                      />
                      <CInputCheckbox :checked.sync="areRulesAccepted">
                        <template v-slot:label>
                          <label v-html="$t('common.labels.acceptConditions')"> </label>
                        </template>
                      </CInputCheckbox>
                    </CCardBody>
                    <CCardFooter>
                      <CButton type="submit" color="success" :disabled="!areRulesAccepted"> {{ $t('common.buttons.confirmAndContinue') }}</CButton>
                    </CCardFooter>
                  </CForm>

                  <CForm v-if="currentStep === 'confirmation'" class="form-confirmation" novalidate>
                    <CCardFooter>
                      <CButton type="button" color="success" @click="goToInitialAssessmentPage"> {{ $t('common.buttons.startReview') }}</CButton>
                      <CButton type="button" variant="outline" color="success" @click="openDashboard"> {{ $t('common.buttons.skip') }}</CButton>
                    </CCardFooter>
                  </CForm>
                </CCard>
              </div>
            </CCol></CRow
          >
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';
import 'bootstrap-datepicker';
import _ from 'lodash';
import config from '@/config';

export default {
  name: 'Register',
  components: {},
  mixins: [apiErrorsMixin],
  data() {
    return {
      steps: [
        {
          name: 'generalInfo',
          label: this.$t('common.labels.generalInfoStepNameLeft'),
        },
        {
          name: 'personalData',
          label: this.$t('common.labels.personalDataStepName'),
        },
        {
          name: 'confirmation',
          label: this.$t('common.labels.confirmationDataStepName'),
        },
      ],
      currentStep: 'generalInfo',
      progressBar: 33,
      isFormSubmitted: false,
      isRegisterButtonDisabled: false,
      isRequestInProgress: false,
      areRulesAccepted: false,
      newUser: {
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        firstName: '',
        lastName: '',
      },
      config,
      genderOptions: [
        { label: this.$t('common.labels.male'), value: 'male' },
        { label: this.$t('common.labels.female'), value: 'female' },
      ],
      programCode: '',
    };
  },
  created() {},
  mounted() {
    this.programCode = this.$route.params.programCode || this.$route.query.programCode;
    this.$store.dispatch('selectProgram', this.programCode).catch((err) => {
      alert(`Attention le programme ${this.programCode} n'existe pas.`);
    });

    const { token } = this.$route.query;

    if (!this.$store.state.token && token) {
      this.$store.commit('token', token);
    }

    this.$http.get('api/auth/user').then((res) => {
      const { user } = res.data;

      if (user && user.hasConfirmedEmail) {
        if (user.hasCompletedRegistration) {
          this.$router.push('/dashboard');
        } else {
          this.currentStep = 'personalData';
          this.updateProgressBar();
        }
      }
    });
  },
  computed: {
    email() {
      return this.newUser && this.newUser.email;
    },
    password() {
      return this.newUser && this.newUser.password;
    },
  },
  methods: {
    isStepCompleted(stepName) {
      if (!stepName) {
        return;
      }

      const index = this.steps.findIndex((step) => step && step.name === stepName);
      const currentStepIndex = this.steps.findIndex((step) => step && step.name === this.currentStep);

      return index < currentStepIndex;
    },
    isFormValid() {
      return this.isEmailValid() && this.password;
    },
    isEmailValid() {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.email);
    },
    isPasswordValid(value) {
      return /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(value);
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    registerUser() {
      this.$store.commit('user', '');
      this.$store.dispatch('logout');
      this.isFormSubmitted = true;

      if (
        this.isRequestInProgress ||
        !this.isEmailValid() ||
        !this.isPasswordValid(this.newUser.password) ||
        this.newUser.password !== this.newUser.confirmPassword
      ) {
        return;
      }

      this.$notifications.clear();
      this.isRequestInProgress = true;
      if (this.$store.state.selectedProgram) {
        this.newUser.programCode = this.$store.state.selectedProgram.code;
      }
      if (this.programCode) {
        this.newUser.programCode = this.programCode;
      }
      this.$http
        .post('/api/user', {
          ..._.pick(this.newUser, ['email', 'password', 'firstName', 'lastName', 'programCode']),
          studyCode: this.$store.state.studyCode,
        })
        .then((res) => {
          this.$store.commit('studyCode', null);
          this.$notify({
            title: 'Merci pour votre inscription',
            message: this.$t('common.messages.confirmationRequired'),
            type: 'success',
            timeout: 0,
            horizontalAlign: 'center',
            verticalAlign: 'top',
            class: 'text-light',
          });

          this.isRegisterButtonDisabled = true;
          this.isFormSubmitted = false;
          this.newUser = {};

          this.$store.dispatch('user', res.data.user);
          this.$store.commit('token', res.data.token);
        })
        .catch((err) => {
          this.isRequestInProgress = false;
          let toastMessage = "Une erreur s'est produite veuillez rafraichir la page";
          if (err.response.data.message === 'error_conflict_email') {
            toastMessage = 'Cette adresse E-mail existe déjà, cliquez sur Login pour vous connecter à la plate-forme';
          }
          this.$notify({
            title: toastMessage,
            type: 'warning',
          });
        })
        .then(() => {
          this.isRequestInProgress = false;
        });
    },
    completeRegistration() {
      this.isFormSubmitted = true;

      if (
        this.isRequestInProgress ||
        !this.newUser.firstName ||
        !this.newUser.lastName ||
        !this.newUser.weight ||
        !this.newUser.height ||
        !this.areRulesAccepted
      ) {
        return;
      }

      const { user } = this.$store.state;

      this.$notifications.clear();
      this.isRequestInProgress = true;

      const birthDate = document.getElementById('birthdate-datepicker').value;

      this.$http
        .put(`/api/user/${user && user.id}`, {
          ..._.pick(this.newUser, ['firstName', 'lastName', 'gender', 'weight', 'height', 'gender']),
          birthdate: birthDate || undefined,
          acceptedTermsId: this.areRulesAccepted,
          hasCompletedRegistration: true,
        })
        .then((res) => {
          this.$notify({
            title: this.$t('common.messages.successfullyUpdated'),
            type: 'success',
          });

          this.currentStep = 'confirmation';
          this.updateProgressBar();
        })
        .catch((err) => {
          this.isRequestInProgress = false;
          const toastMessage = "Une erreur s'est produite, veuillez vérifier que tous les champs sont bien remplis";
          this.$notify({
            title: toastMessage,
            type: 'warning',
          });
        })
        .then(() => {
          this.isRequestInProgress = false;
        });
    },
    openDashboard() {
      this.currentStep = null;
      this.$router.push('/dashboard');
    },
    goToInitialAssessmentPage() {
      this.currentStep = null;
      this.$store.dispatch('getInitialAssessmentData').then(() => {
        const { initialAssessmentQuizzes } = this.$store.state;

        if (initialAssessmentQuizzes && initialAssessmentQuizzes.length) {
          this.$router.push(`/quiz/${initialAssessmentQuizzes[0].id}`);
        } else {
          this.$router.push('/app/dashboard');
        }
      });
    },
    updateProgressBar() {
      this.progressBar = ((this.steps.findIndex((step) => step.name === this.currentStep) + 1) / this.steps.length) * 100;
    },
  },
};
</script>

<style scoped>
.datepicker-input {
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem;
  border: 1px solid !important;
  color: #495057 !important;
  background-color: #ffffff !important;
  border-color: #ced4da !important;
}
</style>
