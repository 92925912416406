<template>
  <CCard class="p-0 card-modules">
    <CCardHeader><span class="f-18">Mon programme</span></CCardHeader>
    <CCardBody class="text-white">
      <CCard
        col="12"
        :style="{ 'background-color': module.color || '#6B6FC6' }"
        class="flex-row align-items-center p-2 w-100 cursor-pointer"
        v-for="(module, index) in modules"
        :key="index"
        @click="() => $router.push({ name: 'Modules', params: { id: module.id } })"
      >
        <div>
          <div class="progress progress-circle mr-2">
            <span class="progress-left">
              <span
                class="progress-bar"
                :style="progressBar(module.progress > 50 ? (module.progress - 50) * 2 : 0)"
              ></span>
            </span>
            <span class="progress-right">
              <span
                class="progress-bar"
                :style="progressBar(module.progress > 50 ? 100 : module.progress)"
              ></span>
            </span>
            <div class="progress-value">
              <div>{{ roundFloatValue(module.progress || 0, 0) }}%</div>
            </div>
          </div>
        </div>
        <div>
          <p class="text-white m-0 pt-1 font-weight-bolder">{{ module.title }}</p>
          <p class="text-white m-0 ">
            {{ module.workshopAmount }} Ateliers, {{ module.unitAmount }} Chapitres
          </p>
        </div>
        <a
          @click="() => $router.push({ name: 'Modules', params: { id: module.id } })"
          class="pr-1 font-weight-light ml-auto d-lg-down-none white-space-nowrap"
        >
          Voir + <span class="text-white align-middle">→</span>
        </a>
      </CCard>
    </CCardBody>
  </CCard>
</template>

<script>
import utils from '../../utils';

export default {
  name: 'modulesProgression',
  data() {
    return {
      modules: [],
    };
  },
  watch: {
    // eslint-disable-next-line
    '$store.state.selectedProgram': async function() {
      await this.getModules();
    },
  },
  async mounted() {
    await this.getModules();
  },
  methods: {
    roundFloatValue: utils.roundFloatValue,
    progressBar(progress) {
      return { transform: `rotate(${(progress * 180) / 100}deg)` };
    },
    async getModules() {
      const { data } = await this.$http.get(
        `/api/stats/dashboard/module?filters[programId]=${this.$store.state.selectedProgram
        && this.$store.state.selectedProgram.id}`,
      );
      if (data && data.body) {
        this.modules = data.body.sort((a, b) => (b.order - a.order)) || [];
      }
    },
  },
};
</script>
