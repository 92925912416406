<template>
  <div class="c-app page-quiz flex-row align-items-center" :class="{ 'bg-white': isEmbedded }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4" class="quiz-sidebar d-sm-down-none" v-if="!isEmbedded">
          <h3>DOCLIVI</h3>
          <div class="quiz-question-info">
            <div v-if="questions && questions.length && questionIndex !== -1" class="quiz-progress">
              {{ questionIndex + 1 }} / {{ questions.length }}
            </div>
            <div v-if="question && question.title" class="quiz-description">
              {{ question.title }}
            </div>
            <div
              v-if="question && question.content"
              class="quiz-description quiz-content-html overflow-hidden"
              v-html="question.content"
            ></div>
          </div>
          <CButton color="link" @click="() => goToRoute('/app/dashboard')">{{
            $t('common.buttons.leave')
          }}</CButton>
        </CCol>
        <CCol class="quiz-sidebar d-md-none" v-if="quiz">
          <div class="d-md-none quiz-progress-mobile text-center">
            <div class="btn btn-link float-left" @click="() => goToRoute('/app/dashboard')">
              {{ $t('common.buttons.leave') }}
            </div>
            <b>{{
              quiz.isInitialAssessment
                ? $t('common.labels.initialAssessment')
                : $t('common.labels.quiz')
            }}</b>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: ((questionIndex + 1) / questions.length) * 100 + '%' }"
              >
                {{ questionIndex + 1 }}/{{ questions.length }}
              </div>
              <div
                class="progress-bar filler"
                role="progressbar"
                :style="{ width: 100 - ((questionIndex + 1) / questions.length) * 100 + '%' }"
              ></div>
            </div>
          </div>
        </CCol>
        <CCol :md="isEmbedded ? 12 : 8" class="content">
          <CRow class="justify-content-center" :class="{ 'h-100': !isEmbedded }">
            <CCol
              md="12"
              class="quiz-container d-flex flex-column justify-content-start align-items-center"
              :class="{ embedded: isEmbedded }"
            >
              <div class="quiz-content d-flex flex-column justify-content-center align-items-start">
                <div v-if="question && question.title" class="quiz-description d-md-none">
                  {{ question.title }}
                </div>
                <div class="d-flex flex-column flex-grow-1 justify-content-center w-100">
                  <template v-if="isEmbedded">
                    <h4 v-if="question && isEmbedded" class="quiz-question-description">
                      {{ question.title }}
                    </h4>
                  </template>
                  <template v-else>
                    <h4
                      v-if="
                        question && (question.type === 'multiple' || question.type === 'survey')
                      "
                      class="quiz-question-description"
                    >
                      {{ $t('common.labels.multipleQuizQuestion') }}
                    </h4>
                    <h4
                      v-if="question && question.type === 'single'"
                      class="quiz-question-description"
                    >
                      {{ $t('common.labels.singleQuizQuestion') }}
                    </h4>
                    <h4
                      v-if="question && question.type === 'open'"
                      class="quiz-question-description"
                    >
                      {{ $t('common.labels.openQuizQuestion') }}
                    </h4>
                  </template>
                  <div v-if="question && question.type === 'single'" class="quiz-answer-area">
                    <div
                      v-for="(answerModel, index) in availableAnswers"
                      :key="index"
                      class="quiz-answer-single"
                    >
                      <CInputRadio
                        :label="answerModel.answer"
                        :value="answerModel.id"
                        :custom="true"
                        :name="answerModel.answer"
                        :checked="isAnswerSelected(answerModel)"
                        :inline="true"
                        @click="() => toggleAnswer(question.type, answerModel)"
                      />
                    </div>
                  </div>
                  <div
                    v-if="question && (question.type === 'multiple' || question.type === 'survey')"
                    class="quiz-answer-area"
                  >
                    <div
                      v-for="(answerModel, index) in availableAnswers"
                      :key="index"
                      class="quiz-answer-multiple"
                    >
                      <CInputCheckbox
                        :label="answerModel.answer"
                        :value="answerModel.id"
                        :custom="true"
                        :name="answerModel.answer"
                        :checked="isAnswerSelected(answerModel)"
                        :inline="true"
                        @click="() => toggleAnswer(question.type, answerModel)"
                      />
                    </div>
                  </div>
                  <div v-if="question && question.type === 'open'" class="quiz-answer-area">
                    <div class="quiz-answer-open">
                      <CInput v-model="currentOpenAnswer" placeholder="Ma réponse" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-container">
                <CButton
                  type="button"
                  color="success"
                  :disabled="!canMoveToNextQuestion"
                  @click="answerQuestion"
                >
                  {{ $t('common.buttons.confirmAndContinue') }}</CButton
                >
              </div>
            </CCol></CRow
          >
        </CCol>
      </CRow>
      <CModal
        :show.sync="showResultModal"
        :centered="true"
        size="sm"
        :backdrop="true"
        :closeOnBackdrop="false"
        @update:show="redirectToNextQuizIfNeeded"
      >
        <template #header>
          <h5 class="modal-title mb-3">Quiz terminé !</h5>
          <CButton @click="redirectToNextQuizIfNeeded()" class="close text-primary mt-2 mr-2">
            x
          </CButton>
        </template>
        <template #body-wrapper>
          <div class="text-center overflow-hidden">
            Bravo d’avoir terminé ce quiz 👏🏻 ! Voici vos résultats ⬇️<br />
            <span v-html="quizGrading"></span><br />
          </div>
        </template>
        <template #footer-wrapper><div></div></template>
      </CModal>
      <CModal
        :show.sync="showAdviceModal"
        :centered="true"
        size="sm"
        :backdrop="true"
        :closeOnBackdrop="false"
        @update:show="openNextQuestion"
      >
        <template #header>
          <h5 class="modal-title mb-3">La réponse des experts Doclivi 🧑🏽‍⚕️</h5>
          <CButton @click="openNextQuestion()" class="close text-primary mt-2 mr-2">
            x
          </CButton>
        </template>
        <template #body-wrapper>
          <div class="text-center" v-for="(advice, index) in adviceList" :key="index">
            {{ advice }}<br />
          </div>
        </template>
        <template #footer-wrapper><div></div></template>
      </CModal>
    </CContainer>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';

const quizQuestionSortFunction = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }

  if (a.order > b.order) {
    return 1;
  }

  return a.id < b.id ? -1 : 1;
};

export default {
  name: 'QuizPage',
  mixins: [apiErrorsMixin],
  props: {
    quizId: Number,
    isEmbedded: { type: Boolean, default: false },
  },
  data() {
    return {
      quiz: null,
      questions: [],
      questionIndex: -1,
      availableAnswers: [],
      currentAnswers: [],
      currentOpenAnswer: null,
      showResultModal: false,
      quizGrading: '',
      showAdviceModal: false,
    };
  },
  computed: {
    question() {
      return this.questions ? this.questions[this.questionIndex] : null;
    },
    adviceList() {
      return this.availableAnswers
        ? this.availableAnswers
          .filter(
            answer =>
              !!answer.advice && this.currentAnswers && this.currentAnswers.includes(answer.id),
          )
          .map(answer => answer.advice)
        : [];
    },
    isInitialAssessment() {
      return this.quiz && this.quiz.isInitialAssessment;
    },
    canMoveToNextQuestion() {
      if (!this.question) {
        return false;
      }

      if (['single', 'multiple', 'survey'].includes(this.question.type)) {
        return this.currentAnswers && this.currentAnswers.length;
      }

      if (this.question.type === 'open') {
        return this.currentOpenAnswer;
      }

      return true;
    },
  },
  mounted: function mounted() {
    this.loadQuizData();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.quizId) {
      this.loadQuizData(to.params.quizId);
    }

    next();
  },
  methods: {
    async loadQuizData(quizId) {
      quizId = quizId || this.quizId || this.$route.params.quizId;
      if (!this.isEmbedded) {
        this.$store.dispatch('getInitialAssessmentData');
      }
      if (!quizId) {
        this.$notify({
          title: this.$t('common.messages.error_missing_quiz'),
          type: 'warning ',
        });
        return this.redirectToNextQuizIfNeeded();
      }

      try {
        const { data } = await this.$http.get(`/api/quiz/${quizId}`);

        if (!data || !data.body) {
          this.$notify({
            title: this.$t('common.messages.error_missing_quiz'),
            type: 'warning ',
          });

          return this.redirectToNextQuizIfNeeded();
        }

        this.quiz = data && data.body;
        this.questions = [];

        const { data: questionResponse } = await this.$http.get(
          `/api/quiz/${quizId}/question?returnAllRecords=1`,
        );
        const questions = questionResponse && questionResponse.body ? questionResponse.body : [];

        if (!questions.length) {
          return this.redirectToNextQuizIfNeeded();
        }

        if (this.isEmbedded) {
          this.questions = (questions || []).sort(quizQuestionSortFunction);
        } else {
          const { data: progressionResponse } = await this.$http.get(
            `/api/quiz/${quizId}/progression`,
          );

          if (!progressionResponse || progressionResponse.status === 'quiz_completed') {
            return this.redirectToNextQuizIfNeeded();
          }

          const unansweredQuestions = progressionResponse.unansweredQuestions || [];

          this.questions = unansweredQuestions.sort(quizQuestionSortFunction);
        }
        if (this.questions && this.questions.length) {
          this.questionIndex = 0;
          this.getQuestionAnswers();
        } else {
          return this.redirectToNextQuizIfNeeded();
        }
      } catch (e) {
        console.warn(e);
      }
    },
    isAnswerSelected(model) {
      return model && this.currentAnswers && this.currentAnswers.indexOf(model.id) !== -1;
    },
    toggleAnswer(questionType, answerModel) {
      if (!answerModel) {
        return;
      }

      this.currentAnswers = this.currentAnswers || [];

      if (questionType === 'single') {
        this.currentAnswers = [answerModel.id];
      } else if (questionType === 'multiple' || questionType === 'survey') {
        if (this.currentAnswers.indexOf(answerModel.id) === -1) {
          this.currentAnswers.push(answerModel.id);
        } else {
          this.currentAnswers = this.currentAnswers.filter(answerId => answerId !== answerModel.id);
        }
      }
    },
    clearAnswerData() {
      this.currentOpenAnswer = null;
      this.currentAnswers = [];
      this.availableAnswers = [];
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    async redirectToNextQuizIfNeeded() {
      const { initialAssessmentQuizzes } = this.$store.state;
      let result;
      if (this.quiz) {
        try {
          const { data } = await this.$http.post(`/api/quiz/${this.quiz.id}/generate-result`);
          result = data && data.body;
          this.$store.dispatch('refreshUser');
        } catch (e) {
          console.warn(e);
        }
      }
      if (this.isEmbedded) {
        this.$emit('quiz-completed', { quiz: this.quiz, result });
        return;
      }

      if (this.showResultModal) {
        this.showResultModal = false;
        this.quizGrading = '';
      } else {
        const rating = result && result[0] && result[0].rating;

        if (rating && this.questions && this.questions.length) {
          this.showResultModal = true;
          this.quizGrading = rating;
          return;
        }
      }

      if (
        !this.quiz
        || !this.quiz.isInitialAssessment
        || !initialAssessmentQuizzes
        || !initialAssessmentQuizzes.length
      ) {
        return this.postponeQuiz();
      }

      const currentQuizIndex = initialAssessmentQuizzes.findIndex(
        quiz => quiz && quiz.id === this.quiz.id,
      );

      if (currentQuizIndex === -1) {
        return this.postponeQuiz();
      }

      const nextQuiz = initialAssessmentQuizzes[currentQuizIndex + 1];

      if (nextQuiz) {
        return this.$router.push(`/quiz/${nextQuiz.id}`);
      }

      this.postponeQuiz();
    },
    async getQuestionAnswers() {
      if (!this.question) {
        return;
      }

      this.clearAnswerData();

      if (this.question.type === 'open') {
        return;
      }

      try {
        const { data: response } = await this.$http.get(
          `/api/quiz-question/${this.question.id}/answer?returnAllRecords=1`,
        );

        if (!response || !response.body) {
          this.$notify({
            title: this.$t('common.messages.error_missing_quiz_answers'),
            type: 'warning ',
          });
          return;
        }

        this.availableAnswers = response.body;
      } catch (e) {
        console.warn(e);
      }
    },
    answerQuestion() {
      const { user } = this.$store.state;

      if (!this.quiz || !user) {
        return;
      }

      if (!this.question) {
        return this.redirectToNextQuizIfNeeded();
      }

      if (this.question.type === 'open' && !this.currentOpenAnswer) {
        return;
      }

      if (this.question.type !== 'open' && (!this.currentAnswers || !this.currentAnswers.length)) {
        this.openNextQuestion();
        return;
      }

      this.$http
        .post('/api/quiz-user-answer', {
          userId: user.id,
          quizQuestionId: this.question.id,
          openAnswer: this.question.type === 'open' ? this.currentOpenAnswer : undefined,
          answers: this.question.type === 'open' ? undefined : this.currentAnswers,
        })
        .then(() => {
          this.openNextQuestion();
        })
        .catch(this.apiErrorCallback);
    },
    openNextQuestion() {
      if (
        this.question
        && this.question.type === 'survey'
        && this.adviceList
        && this.adviceList.length
      ) {
        if (this.showAdviceModal === false) {
          this.showAdviceModal = true;
          return;
        }
        this.showAdviceModal = false;
      }

      this.clearAnswerData();

      this.questions = this.questions || [];
      if (this.questionIndex < 0 || this.questionIndex > this.questions.length - 2) {
        this.redirectToNextQuizIfNeeded();
      } else {
        ++this.questionIndex;
        this.getQuestionAnswers();
      }
    },
    postponeQuiz() {
      const isInitialAssessment = this.quiz && this.quiz.isInitialAssessment;

      this.$router.push(isInitialAssessment ? '/welcome' : '/app/dashboard');
    },
  },
};
</script>
