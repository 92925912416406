function convertDurationToHoursAndMinutes(duration, options = { short: false }) {
  duration = +duration;
  const isShortFormat = options && options.short;

  if (duration >= 0) {
    const hours = Math.floor(duration / 60);
    const mins = Math.floor(duration - hours * 60);


    // eslint-disable-next-line no-nested-ternary
    const time = `${hours > 0 ? `${hours}h ` : isShortFormat ? '0h ' : ''}${
      // eslint-disable-next-line no-nested-ternary
      mins < 10 && mins > 0 ? `0${mins}m ` : mins > 9 ? `${mins}m ` : hours ? '' : '0m'
    }`;

    if (isShortFormat) {
      const shortFormat = time.replace('h ', ':').replace('m', '');
      return shortFormat.endsWith(':') ? `${shortFormat}00` : shortFormat;
    }
    return time;
  }

  if (isShortFormat) {
    return '';
  }
  return '0m';
}

function roundFloatValue(value, digits) {
  return value % 1 === 0 ? value : (Math.floor(value * 100) / 100).toFixed(digits);
}

export default { convertDurationToHoursAndMinutes, roundFloatValue };
