<template>
  <div class="c-app page-login flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4" class="auth-sidebar">
          <h3>DOCLIVI</h3>
        </CCol>
        <CCol md="8" class="content">
          <CRow>
            <CCol
              xs="12"
              md="12"
              class="form-container d-flex justify-content-center align-items-center"
            >
              <div class="form-login d-flex flex-column justify-content-center align-items-start">
                <h3>{{ $t('common.labels.welcome') }}!</h3>
                <h4>{{ $t('common.labels.take-care') }}</h4>
                <CCard>
                  <CForm novalidate @submit.prevent="submitForm">
                    <CCardBody>
                      <CInput
                        type="email"
                        required
                        v-model="email"
                        autocomplete="email"
                        :addInputClasses="{
                          'is-valid': isEmailValid(),
                          'is-invalid': isFormSubmitted && !isEmailValid(),
                        }"
                        :label="$t('common.field.email')"
                        :placeholder="$t('common.placeholders.email')"
                        :invalid-feedback="
                          $t(email ? 'common.labels.enterValidEmail' : 'common.labels.emptyField')
                        "
                      />
                      <CInput
                        type="password"
                        required
                        :addInputClasses="{
                          'is-valid': password,
                          'is-invalid': isFormSubmitted && !password,
                        }"
                        v-model="password"
                        autocomplete="current-password"
                        :label="$t('common.field.password')"
                        :placeholder="$t('common.placeholders.password')"
                        :invalid-feedback="$t('common.labels.emptyField')"
                      />
                    </CCardBody>
                    <CCardFooter>
                      <CButton type="submit" color="success">
                        <ClipLoader
                          :loading="isRequestInProgress"
                          color="white"
                          size="25px"
                        ></ClipLoader>
                        {{ isRequestInProgress ? '' : $t('common.buttons.login') }}</CButton
                      >
                      <div class="btn-container">
                        <CButton
                          color="link"
                          class="h-auto"
                          @click="() => goToRoute('/forgot-password')"
                          >{{ $t('common.buttons.forgot-pwd') }}</CButton
                        >
                      </div>
                    </CCardFooter>
                  </CForm>
                </CCard>
              </div>
            </CCol></CRow
          >
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import { apiErrorsMixin } from 'vue-aw-components';
import { setTimeout } from 'timers';
import config from '@/config';

export default {
  name: 'Login',
  mixins: [apiErrorsMixin],
  data() {
    return {
      isFormSubmitted: false,
      isRequestInProgress: false,
      email: null,
      password: null,
      config,
      programCode: '',
    };
  },
  mounted() {
    if (this.$store.state.user && this.$store.state.user.id && this.$store.state.user) {
      this.$router.push('/app/dashboard');
      return;
    }

    this.programCode = this.$route.params.programCode || this.$route.query.programCode;
  },
  methods: {
    isFormValid() {
      return this.isEmailValid() && this.password;
    },
    isEmailValid() {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.email);
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    submitForm() {
      this.isFormSubmitted = true;

      if (!this.isFormValid()) {
        return;
      }

      this.isRequestInProgress = true;

      this.$http
        .post('/api/auth/login', {
          email: this.email,
          password: this.password,
          studyCode: this.$store.state.studyCode,
        })
        .then(this.postLogin)
        .catch((err) => {
          this.isRequestInProgress = false;
          let toastMessage = "Une erreur s'est produite veuillez rafraichir la page";
          if (err.response && err.response.status === 401) {
            switch (err.response.data.message) {
              case 'error_invalid_credentials':
                toastMessage = 'Mot de passe erroné';
                break;
              case 'error_unknown_email':
                this.email = '';
                toastMessage =
                  'Vous n\'êtes pas enregistré sur la plate-forme, cliquez sur "s\'inscrire" pour vous enregistrer.';
                break;
              case 'error_study_code_already_set':
                toastMessage =
                  'Votre compte est déjà associé à une autre étude, veuillez contacter un administrateur.';
                this.$store.commit('studyCode', null);
                break;
              default:
                this.password = '';
                toastMessage = 'Email ou mot de passe erroné.';
                break;
            }
          }
          this.$notify({
            title: toastMessage,
            type: 'warning',
          });
        })
        .then(() => {
          this.isRequestInProgress = false;
        });
    },

    postLogin(res) {
      this.$store.commit('studyCode', null);

      if (res.data) {
        if (res.data && res.data.user && res.data.user.isActive) {
          if (!res.data.user.programId) {
            return this.$notify({
              title:
                "Votre compte n'a pas de programme associé. Merci de contacter un administrateur.",
              type: 'danger',
            });
          }
          this.$store.commit('token', res.data.token);
          this.$store.dispatch('user', res.data.user);
          this.$store.commit('currentLocale', res.data.user.locale || config.defaultLocale);

          this.isFormSubmitted = false;
          this.$store
            .dispatch('selectProgram')
            .then(() => {
              if (res.data.user.hasCompletedRegistration) {
                this.$router.push('/dashboard');
              } else if (
                this.$store.state.selectedProgram &&
                this.$store.state.selectedProgram.code
              ) {
                this.$router.push(`/${this.$store.state.selectedProgram.code}/register`);
              } else {
                this.$router.push('/dashboard');
              }

              setTimeout(async () => {
                this.$notifications.clear();
                this.$notify({
                  title: this.$t('common.messages.loginWelcome', {
                    name: `${res.data.user.firstName}
                ${res.data.user.lastName}`,
                  }),
                  type: 'success',
                });
              }, 250);
            })
            .catch((err) => {
              console.warn(err);
              this.$notify({
                title:
                  "Il n'y a pas de programme associé à votre compte. Merci de contacter un administrateur",
                type: 'danger',
              });
            });

          return;
        }
        this.$notify({
          title: this.$t('common.messages.no_access_account'),
          type: 'warning',
        });
      }
    },
  },
};
</script>
