<template>
  <div
    class="program-page tab-content d-flex flex-column align-items-center justify-content-between"
  >
    <div class="d-flex flex-column align-items-center">
      <span class="program-page-title">{{ $t('common.labels.thanks') }}</span>
      <h4 class="program-page-description mb-4">{{ $t('common.labels.program-welcome-text') }}</h4>
      <h4 v-if="selectedProgram" class="program-page-description mb-4">
        {{ $t('common.labels.program-welcome-text-2') }} {{ selectedProgram.title }}
      </h4>
      <h4 class="program-page-description mb-4">
        {{ $t('common.labels.program-welcome-text-3') }}
      </h4>
    </div>
    <div class="slide-container">
      <CButton color="success" class="slider-nav d-md-down-none" @click="goToPrevSlide">
        <CIcon :src="dropdownIcon"
      /></CButton>
      <hooper
        @slide="onSlideChanged"
        ref="slider"
        :centerMode="true"
        :mouseDrag="true"
        :infiniteScroll="true"
        :auto-play="false"
        :wheelControl="false"
      >
        <slide v-for="(module, index) in modules" :key="index">
          <div class="card card-primary h-100" text="first">
            <CRow class="h-100">
              <CCol
                sm="12"
                lg="7"
                class="d-flex flex-column justify-content-between align-items-start"
              >
                <div>
                  <h3
                    class="text-primary mb-3"
                    :style="{ color: module.color ? `${module.color}!important` : '#47776e' }"
                  >
                    {{ module.title }}
                  </h3>
                  <div class="module-picture d-lg-none">
                    <img :src="getModuleImage(index)" />
                  </div>
                  <span class="module-description"
                    >{{ $t('common.labels.module-goal') }}:
                    <span>{{ module.description }}</span></span
                  >
                  <div class="mt-4 d-block d-lg-flex justify-content-start align-items-center">
                    <div class="module-info-row">
                      <CIcon :src="clockIcon" class="mr-2" />
                      <b
                        >{{ $t('common.labels.module-time') }}:
                        {{ convertDurationToHoursAndMinutes(module.estimatedDuration) }}</b
                      >
                    </div>
                    <div class="module-info-row">
                      <CIcon :src="checkListIcon" class="mr-2" />
                      <b>{{ $t('common.labels.number-of-sections') }}: {{ workshopsCount }}</b>
                    </div>
                  </div>
                </div>
                <div class="btn-container d-flex justify-content-start w-100">
                  <CButton color="success" class="mt-5" @click="goToRoute('/app/modules')">
                    {{ $t('common.buttons.begin') }}</CButton
                  >
                </div>
              </CCol>
              <CCol sm="5" class="module-picture d-md-down-none">
                <img :src="getModuleImage(index)" />
              </CCol>
            </CRow>
          </div>
        </slide>
      </hooper>
      <CButton color="success" class="slider-nav d-md-down-none" @click="goToNextSlide">
        <CIcon :src="dropdownIcon"
      /></CButton>
    </div>
    <div class="btn-container">
      <span>{{ $t('common.labels.or') }}</span>
      <CButton color="link" @click="() => goToRoute('/app/dashboard')">{{
        $t('common.buttons.visit-mode')
      }}</CButton>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import utils from '../../utils';
import dropdownIcon from '../../assets/icons/dropdown.svg';
import clockIcon from '../../assets/icons/Clock.svg';
import checkListIcon from '../../assets/icons/CheckList.svg';
import nutritionImg from '../../assets/images/nutrition.svg';
import formeImg from '../../assets/images/forme.svg';
import equilibreImg from '../../assets/images/equilibre.svg';
import phytotherapieImg from '../../assets/images/phytotherapie.svg';

export default {
  name: 'StudyLogin',
  components: {
    Hooper,
    Slide,
  },

  data() {
    return {
      modules: [],
      workshops: [],
      currentModule: null,
      dropdownIcon,
      clockIcon,
      checkListIcon,
      moduleImages: [nutritionImg, formeImg, equilibreImg, phytotherapieImg],
    };
  },
  async mounted() {
    await this.getPrograms();
  },
  computed: {
    programs() {
      return this.$store.state.programs || [];
    },
    selectedProgram() {
      return this.$store.state.selectedProgram;
    },
    workshopsCount() {
      return this.workshops ? this.workshops.length : 0;
    },
  },
  watch: {
    // eslint-disable-next-line
    '$store.state.selectedProgram': function() {
      this.getModules();
    },
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    onSlideChanged({ currentSlide }) {
      this.currentModule
        = this.modules && this.modules[currentSlide] ? this.modules[currentSlide] : null;
      this.getWorkshops();
    },

    async getWorkshops() {
      if (!this.currentModule) {
        return;
      }

      const { data } = await this.$http.get(
        `/api/workshop?filters[moduleId]=${this.currentModule.id}&filters[isPublished]=1&sort[order]=DESC`,
      );

      if (data && data.body && data.body.length) {
        this.workshops = data.body.filter(
          workshop => workshop && workshop.content && workshop.title,
        );
      }
    },

    goToPrevSlide() {
      if (this.$refs.slider) {
        this.$refs.slider.slidePrev();
      }
    },

    goToNextSlide() {
      if (this.$refs.slider) {
        this.$refs.slider.slideNext();
      }
    },
    getModuleImage(index) {
      if (!this.modules || !this.modules.length) {
        return;
      }

      return this.moduleImages[index % this.modules.length];
    },

    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },

    getPrograms() {
      this.$store.dispatch('getProgram');
    },

    async getModules() {
      if (!this.selectedProgram) {
        this.modules = [];
        this.currentModule = null;
        this.workshops = [];
        return;
      }

      const { data } = await this.$http.get(
        `/api/module?filters[programId]=${this.selectedProgram.id}&sort[order]=ASC`,
      );
      if (data && data.body) {
        this.modules = data.body;
        this.currentModule = this.modules && this.modules.length ? this.modules[0] : null;
        this.getWorkshops();
      }
    },
  },
};
</script>

<style scoped></style>
