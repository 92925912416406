<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="value => $store.commit('set', ['sidebarShow', value])"
    class="dashboard-tour-show-step-0"
  >
    <CSidebarBrand to="/">
      <span class="c-sidebar-brand-full f-18">
        Doclivi
        <template v-if="$store.state.selectedProgram && $store.state.selectedProgram.code">
          <br />
          <small class="text-secondary">{{ $store.state.selectedProgram.code }}</small>
        </template>
      </span>
      <span class="c-sidebar-brand-minimized">L</span>
    </CSidebarBrand>

    <div class="c-sidebar-nav-item d-lg-none pb-0">
      <a href="?" target="_self" class="c-sidebar-nav-link" @click="() => $router.push('/app/settings')">
        <img :src="avatar" alt="Mon profil" class="c-avatar-img" />
        {{ $store.state.user.firstName || 'Profil' }}
      </a>
    </div>

    <CRenderFunction flat :content-to-render="nav">
      <div class="c-sidebar-nav-item">
        <a class="c-sidebar-nav-link"
          ><img src="/img/Logout.ea8b7ccd.svg" role="img" /><!---->
          Se déconnecter
        </a>
      </div>
    </CRenderFunction>
    <div class="c-sidebar-nav-item">
      <a href="?" target="_self" class="c-sidebar-nav-link" @click="() => $router.push('/logout')">
        <img :src="logoutIcon" role="img" /><!---->
        Se déconnecter
        <!---->
      </a>
    </div>
  </CSidebar>
</template>

<script>
// eslint-disable-next-line
import * as _ from 'lodash';
import logoutIcon from '@/assets/icons/Logout.svg';
import avatar from '@/assets/icons/avatar.svg';
import nav from './_nav';

export default {
  name: 'Sidebar',
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    nav() {
      const navData = _.cloneDeep(nav);

      navData.forEach(branch => {
        // eslint-disable-next-line no-underscore-dangle
        if (!branch || !branch._children) {
          return;
        }

        // eslint-disable-next-line no-underscore-dangle
        branch._children = branch._children
          .filter(option => {
            if (!this.$store.state.selectedProgram && option.isProgramRequired) {
              return false;
            }

            return true;
          })
          .map(option => ({
            ...option,
            name: option.i18nKey ? this.$t(option.i18nKey) : option.name,
          }));
      });
      return navData;
    },
  },
  data() {
    return {
      logoutIcon,
      avatar
    };
  },
  mounted() {
    this.$nextTick(() => {
      $('.to-bottom').parent().addClass('mt-auto');
    });
  }
};
</script>
