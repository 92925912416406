import homeIcon from '@/assets/icons/Home.svg';
import moduleIcon from '@/assets/icons/Programmes.svg';
import reportsIcon from '@/assets/icons/Reports.svg';
import objectivesIcon from '@/assets/icons/Objectives.svg';
import mediasIcon from '@/assets/icons/Medias.svg';
// import recepiesIcon from '@/assets/icons/Recepies.svg';
// import quizIcon from '@/assets/icons/Quiz.svg';
import settingsIcon from '@/assets/icons/Settings.svg';
import helpIcon from '@/assets/icons/Help.svg';

export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Accueil',
        i18nKey: 'common.nav.home',
        to: '/app/dashboard',
        icon: {
          src: homeIcon,
        },
        addLinkClasses: 'd-md-down-none'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Programme',
        i18nKey: 'common.nav.modules',
        to: '/app/modules',
        icon: {
          src: moduleIcon,
        },
        isProgramRequired: true,
        addLinkClasses: 'd-md-down-none'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Indicateurs',
        i18nKey: 'common.nav.reports',
        to: '/app/reports',
        icon: {
          src: reportsIcon,
        },
        addLinkClasses: 'd-md-down-none'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Objectifs',
        i18nKey: 'common.nav.goals',
        to: '/app/goals',
        icon: {
          src: objectivesIcon,
        },
        addLinkClasses: 'd-md-down-none'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Médias',
        i18nKey: 'common.nav.media',
        to: '/app/medias',
        icon: {
          src: mediasIcon,
        },
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Paramètres',
        i18nKey: 'common.nav.settings',
        to: '/app/settings',
        icon: {
          src: settingsIcon,
        },
        addLinkClasses: 'to-bottom'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Assistance',
        i18nKey: 'common.nav.assistance',
        to: '/app/help',
        icon: {
          src: helpIcon,
        },
      },
    ],
  },
];
