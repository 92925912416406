var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-app page-login flex-row align-items-center"},[_c('CContainer',[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{staticClass:"auth-sidebar",attrs:{"md":"4"}},[_c('h3',[_vm._v("DOCLIVI")])]),_vm._v(" "),_c('CCol',{staticClass:"content",attrs:{"md":"8"}},[_c('CRow',[_c('CCol',{staticClass:"form-container d-flex justify-content-center align-items-center",attrs:{"xs":"12","md":"12"}},[_c('div',{staticClass:"form-login d-flex flex-column justify-content-center align-items-start"},[_c('h3',[_vm._v(_vm._s(_vm.$t('common.labels.welcome'))+"!")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.$t('common.labels.take-care')))]),_vm._v(" "),_c('CCard',[_c('CForm',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('CCardBody',[_c('CInput',{attrs:{"type":"email","required":"","autocomplete":"email","addInputClasses":{
                        'is-valid': _vm.isEmailValid(),
                        'is-invalid': _vm.isFormSubmitted && !_vm.isEmailValid(),
                      },"label":_vm.$t('common.field.email'),"placeholder":_vm.$t('common.placeholders.email'),"invalid-feedback":_vm.$t(_vm.email ? 'common.labels.enterValidEmail' : 'common.labels.emptyField')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('CInput',{attrs:{"type":"password","required":"","addInputClasses":{
                        'is-valid': _vm.password,
                        'is-invalid': _vm.isFormSubmitted && !_vm.password,
                      },"autocomplete":"current-password","label":_vm.$t('common.field.password'),"placeholder":_vm.$t('common.placeholders.password'),"invalid-feedback":_vm.$t('common.labels.emptyField')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_vm._v(" "),_c('CCardFooter',[_c('CButton',{attrs:{"type":"submit","color":"success"}},[_c('ClipLoader',{attrs:{"loading":_vm.isRequestInProgress,"color":"white","size":"25px"}}),_vm._v("\n                      "+_vm._s(_vm.isRequestInProgress ? '' : _vm.$t('common.buttons.login')))],1),_vm._v(" "),_c('div',{staticClass:"btn-container"},[_c('CButton',{staticClass:"h-auto",attrs:{"color":"link"},on:{"click":() => _vm.goToRoute('/forgot-password')}},[_vm._v(_vm._s(_vm.$t('common.buttons.forgot-pwd')))])],1)],1)],1)],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }