<template>
  <div class="c-app page-login flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4" class="auth-sidebar">
          <h3>DOCLIVI</h3>
        </CCol>
        <CCol md="8" class="content">
          <CRow class="justify-content-center d-sm-down-none">
            <CCol md="12" class="btn-container d-flex justify-content-end align-items-center">
              <CButton
                variant="square"
                color="success"
                class="mx-2"
                @click="() => goToRoute('/login')"
                >{{ $t('common.buttons.login') }}</CButton
              >
            </CCol></CRow
          >
          <CRow class="justify-content-center">
            <CCol md="12" class="form-container d-flex justify-content-center align-items-center">
              <div class="form-login d-flex flex-column justify-content-center align-items-start">
                <h3>{{ $t('common.labels.forgot-pwd') }}!</h3>
                <CCard>
                  <CForm novalidate @submit.prevent="forgotPassword">
                    <CCardBody>
                      <CInput
                        type="email"
                        required
                        v-model="email"
                        autocomplete="email"
                        :addInputClasses="{
                          'is-valid': isFormSubmitted && isEmailValid(),
                          'is-invalid': isFormSubmitted && !isEmailValid(),
                        }"
                        :label="$t('common.field.email')"
                        :placeholder="$t('common.placeholders.email')"
                        :invalid-feedback="
                          $t(email ? 'common.labels.enterValidEmail' : 'common.labels.emptyField')
                        "
                      />
                    </CCardBody>
                    <CCardFooter>
                      <CButton type="submit" color="success">
                        <ClipLoader
                          :loading="isRequestInProgress"
                          color="white"
                          size="25px"
                        ></ClipLoader>
                        {{ isRequestInProgress ? '' : $t('common.buttons.next') }}</CButton
                      >
                      <CButton
                        color="link"
                        class="mt-3 d-md-none"
                        @click="() => goToRoute('/login')"
                        >{{ $t('common.buttons.return') }}</CButton
                      >
                    </CCardFooter>
                  </CForm>
                </CCard>
              </div>
            </CCol></CRow
          >
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import { apiErrorsMixin } from 'vue-aw-components';
import config from '@/config';

export default {
  name: 'ForgotPassword',
  mixins: [apiErrorsMixin],
  components: {},
  data() {
    return {
      isFormSubmitted: false,
      isRequestInProgress: false,
      email: null,
      config,
    };
  },
  methods: {
    isEmailValid() {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.email);
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    forgotPassword() {
      this.isFormSubmitted = true;

      if (!this.isEmailValid()) {
        return;
      }

      this.isRequestInProgress = true;

      this.$http
        .post('/api/auth/forgot', { email: this.email })
        .then(() => {
          this.$notifications.clear();
          this.$notify({
            title: this.$t('common.messages.forgotPasswordSuccess'),
            type: 'success',
          });
        })
        .catch(this.apiErrorCallback)
        .then(() => {
          this.isRequestInProgress = false;
          this.email = '';
          this.isFormSubmitted = false;
        });
    },
  },
};
</script>
